<template>
  <div v-if="products.length > 0" class="home-content" style="padding-top:120px;">

    <div class="main-bg-img"></div>

    <v-row>

      <v-col cols="12" sm="11" md="10" class="mx-auto">
        <h1 class="mt-5 mb-0 text- font-flower main-title" style="">{{ $store.state.app.siteName }}</h1>
        <h2 class="d-none">Mandala of life</h2>
        <div class="font-aladin teal--text text--lighten-1 main-subtitle">
          <span v-for="(letter, l) in mandalaLetters" :key="l" 
                 @mouseover="shrinkColor()" style="opacity:0.7"
                :class="letter.color + '--text'">{{ letter.letter }}</span>
        </div>
      </v-col>

      <!-- SUB MENU BTN -->
      <v-col cols="12" sm="11" md="10" class="mx-auto">
        
          <v-divider></v-divider>

          <v-toolbar color="transparent" elevation="0">
            <v-btn text rounded class="btn-home" to="/gallery">GALERIE</v-btn>
            <v-btn text rounded class="btn-home" to="/shop">BOUTIQUE</v-btn>
            <v-btn text rounded class="btn-home" to="/contact">CONTACT</v-btn>

            <v-spacer/>
            <v-btn icon class="d-none d-sm-flex" target="_blank" href="https://www.facebook.com/profile.php?id=100014815690676">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <!-- <v-btn icon class="d-none d-sm-flex"><v-icon>mdi-instagram</v-icon></v-btn> -->
            <v-btn icon class="d-none d-sm-flex" to="/contact"><v-icon>mdi-at</v-icon></v-btn>
          </v-toolbar>

          <v-divider></v-divider>

      </v-col>
      
      <!-- <v-col cols="11" md="10" class="mx-auto px-0 px-md-4 text-center encar-stage">
        <v-card class="pt-5" style="background-image: url('img/bann_bg.webp');background-size: cover; border-radius:15px;">
          <v-col class="pt-5">
            <h1 class="font-aladin">Du 31 octobre au 3 novembre</h1>
            <v-divider class="my-3"></v-divider>
            <h1 class="font-aladin mb-4">Immersion créative Mandala & géométrie sacrée</h1>
            <h2 class="font-aladin">Un stage de 3 jours à la rencontre de votre créativité intuitive au coeur de la nature.</h2>
            <h2 class="font-aladin">À Lascoutch (cévennes)</h2>
            <v-divider class="my-3"></v-divider>
            <v-btn small fab color="blue" class=" my-1" 
                  href="https://www.facebook.com/events/6504957509540562" target="blank">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn class="ml-2 my-1" to="/contact"><v-icon class="mr-1">mdi-mail</v-icon> Réserver par e-mail</v-btn>
          </v-col>
        </v-card>
      </v-col> -->

      <!-- <v-col cols="12" sm="6" class="mx-auto px-0 px-md-4">
        <img src="/img/flyer_immersion2.webp" height="400"/>
      </v-col> -->

      
      <v-col cols="10" sm="10" class="mx-auto px-0 px-md-4">
        
        <v-row v-if="$store.state.app.data.config.length > 0">
          <v-col cols="12" lg="8" class="px-0 px-md-5">
            <v-alert v-if="configHome2 != null && configHome2.text != ''" color="red darken-3" 
                     outlined style="font-weight: 600 !important;" class="d-inline-block" 
                     v-html="nl2br(configHome2[0].text)">
            </v-alert>
            <p style="font-weight: 600 !important;" 
               v-if="configHome1 != null && configHome1.text != ''"
               v-html="nl2br(configHome1[0].text)">
            </p>
            <!-- <v-btn outlined @click="showMore=!showMore">
              <v-icon small>mdi-chevron-down</v-icon> en savoir plus
            </v-btn> -->
          </v-col>
        </v-row>

        <v-row class="d-none">
          <v-col cols="12" class="px-0">
            <p style="font-weight: 600 !important;">
              <b>Un mot sur ma démarche :</b><br><br>
              Passionné par la fleur de vie et la géométrie sacrée, mes créations sont des pièces uniques, 
              souvent inspirées par les personnes à qui elles sont destinées...
            </p>
            <v-btn outlined @click="showMore=!showMore">
              <v-icon small>mdi-chevron-down</v-icon> en savoir plus
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="d-none">
          <v-col cols="12" class="d-none d-md-flex">
            <p style="font-weight: 600 !important;">
              <b>Un mot sur ma démarche :</b>
            </p>
          </v-col>

          <v-col cols="12" md="6" lg="3" :class="classShowMore">
            <p style="font-weight: 600 !important;">
              <v-icon small color="teal">mdi-circle</v-icon> 
              J'explore depuis plusieurs années la géométrie sacrée sous la forme de mandalas alliant ordre et chaos,
              matière et lumière, structure et libre inspiration. 
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="d-none d-md-flex">
            <p style="font-weight: 600 !important;">
              <v-icon small color="teal">mdi-circle</v-icon> 
              Basées sur la fleur de vie et les proportions du nombre d'or, mes créations sont des pièces uniques, 
              souvent inspirées par les personne à qui elles sont destinées.
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="3" :class="classShowMore">
            <p style="font-weight: 600 !important;">
              <v-icon small color="teal">mdi-circle</v-icon> 
              Les fonds sont faits à l'avance selon la technique de l'<i>acrylic pouring</i>, qui donne un résultat aléatoire à chaque fois. 
              Je réalise chaque dessin au compas et main levée.
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="3" :class="classShowMore">
            <p style="font-weight: 600 !important;">
              <v-icon small color="teal">mdi-circle</v-icon> 
              Je joue ensuite avec les différentes formes géométriques, nombres et dimensions, 
              pour faire apparaître le mandala qui vous correspond. 
            </p>
          </v-col>
        </v-row>

        <br>

        <v-row v-show="true">
          <template v-for="(product) in products">
            <v-col cols="12" sm="6" md="4" lg="3" 
                   class="pa-5 mt-5 text-center" 
                   v-if="product.categories.length > 0 && product.categories[0].name == 'Mandala original'"
                   :key="product.name">
              <v-img class="mx-auto" 
                    :class="product.isCircle ? 'img-rounded' : 'img-squarded'"
                    @click="openDialogProduct(product)"
                    :aspect-ratio="1/1"
                    :src="baseUrl() + product.image"></v-img>
                    <span class="font-dancing" style="font-size:25px;">{{ product.name }}</span>
                    <!-- <br><small>{{ product.description.substr(0, 50) }}...</small> -->
            </v-col>
          </template>
        </v-row>
        
      </v-col>

    </v-row>

    <v-row>
      <v-col cols="12" sm="12" class="pa-0 my-5">
        <v-card elevation="0" color="transparent" class="section">
          <v-col cols="12" sm="12" class="mx-auto text-center font-flower">
            <v-btn x-large style="font-size:1.3em;" color="teal" dark class="ma-3" to="/shop">
            Explorer la boutique <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>
            </v-btn>
            <!-- <v-btn x-large style="font-size:1.3em;" color="teal" dark class="ma-3" to="/commande">Passer une commande</v-btn> -->
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showProductDialog" v-if="productToShow != null"  
              :fullscreen="$vuetify.breakpoint.width < 700"
              transition="dialog-bottom-transition" max-width="600" scrollable>
      <DialogProduct :product="productToShow"></DialogProduct>
    </v-dialog>

  </div>
</template>


<script>
    import core from '../plugins/core.js'

    import DialogProduct from '@/components/main-layout/DialogProduct.vue'
    import '@/assets/css/home.css';
    
    //import { i18n } from 'vue-lang-router';

    export default {
        name: "App",
        components: {
          DialogProduct
        },
        data: function () {
            return {
                products: [],
                productToShow: false,
                showProductDialog: false,
                showMore: false,
                configHome1: null,
                configHome2: null,
                mandalaLetters: [
                  { letter: 'M', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 'n', color: 'white'},
                  { letter: 'd', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 'l', color: 'white'},
                  { letter: 'a', color: 'white'},
                  { letter: 's', color: 'white'},
                  { letter: ' ', color: 'white'},
                  { letter: 'O', color: 'white'},
                  { letter: 'f', color: 'white'},
                  { letter: ' ', color: 'white'},
                  { letter: 'L', color: 'white'},
                  { letter: 'i', color: 'white'},
                  { letter: 'f', color: 'white'},
                  { letter: 'e', color: 'white'},
                ],
                colors: [
                  'blue', 'teal', 'orange', 'red', 'green', 'yellow', 
                  'cyan', 'purple', 'amber', 'brown', 'indigo'
                ]
            };
        },
        mounted: function(){
          this.initData()
          this.$root.$on('closeDialogProduct', () => { this.closeDialogProduct() })
          this.$store.dispatch('app/incStat', '/home')
        },
        methods: {
            
            async initData(){
              await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "product", 
                                query: { public: true }, 
                                sort: { 'created' : -1 } 
                              })
              this.products = this.$store.state.app.data.product


              let config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_TXT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome1 = config.entities[0]

              config = 
               await this.$store.dispatch('app/fetchEntities', 
                              { entityType: "config", 
                                query: { key: 'HOME_ALERT' }, 
                                //sort: { 'created' : -1 } 
                              })
              if(config.error == false)
                this.configHome2 = config.entities[0]

              this.shrinkColor()
              this.timer = setInterval(()=>{
                this.shrinkColor()
              }, 9000)

              console.log("products", this.products)
            },
            shrinkColor(){
              this.mandalaLetters.forEach((letter)=>{
                let rand = parseInt(Math.random() * this.colors.length)
                letter.color = this.colors[rand]
              })
            },
            openDialogProduct(product){
              this.productToShow = product
              this.showProductDialog = true
            },
            closeDialogProduct(){
              this.productToShow = null
              this.showProductDialog = false
            },
            nl2br: function(str, is_xhtml){
              return core.nl2br(str, is_xhtml)
            },
            baseUrl(){ return core.baseUrl() }
        },
        computed:{
          classShowMore(){ return this.showMore ? '' : 'd-none d-md-flex' }
        }
    };
</script>

